import colours from './colours'
import fonts from './fonts'
import typography from './typography'
import utility from './utility'

export default {
  colours,
  fonts,
  typography,
  utility,
}
