export default {
  fieryRose: '#FF416C',
  burningOrange: '#FF4B2B',
  lemon: '#EDCF43',
  cambridge: '#B3D2C2',
  blueGreen: '#5E97AA',
  black: '#000',
  charcoal: '#282C2D',
  grey: '#ACACAC',
  lightGrey: '#DFDFDF',
  offWhite: '#EFEFEF',
  white: '#FFF',
  error: '#FF6961',
  success: '#77DD77',
}
